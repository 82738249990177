import { UserModel } from '@app/domain/UserModel';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { getUsers } from '@app/api/user.api';
import { createAction, PrepareAction,Slice, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface UserState {
  user: UserModel | null;
}

const initialState: UserState = {
  user: readUser(),
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const doGetUser = createAsyncThunk('Users', async () => {
  try {
    // Perform an asynchronous operation (e.g., API call)
    const response = await getUsers();

    // Return the data to be dispatched
    return response?.data;
  } catch (error) {
    // Handle errors
    throw error;
  }
});

export const userSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
