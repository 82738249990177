import styled from 'styled-components';
import { media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { WarningOutlined } from '@ant-design/icons';

export const WarningIcon = styled(WarningOutlined)`
  color: red;
  font-size: 1.35rem;

  margin: 1px;
`;

export const Title = styled(BaseTypography.Text)`
  margin-bottom: 2rem;

  @media only screen and ${media.xl} {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 2.5rem;
  }
`;
