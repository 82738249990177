import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';

export const commonHttpApi = axios.create({
  baseURL: 'https://api.e-mip.com',
 //baseURL: 'http://localhost:3007',
});

commonHttpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers };

  return config;
});

commonHttpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
});

export interface ApiErrorData {
  message: string;
}
