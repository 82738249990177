import React, { useState, useEffect, ReactNode } from 'react';
import { BasicTableRow, Pagination } from 'api/table.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import * as S from './ProcessingLocations.styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseAvatar } from '../../common/BaseAvatar/BaseAvatar';
import { BaseUpload } from '../../common/BaseUpload/BaseUpload';
import { UploadOutlined, InboxOutlined, AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { BaseSpace } from '../../common/BaseSpace/BaseSpace';
import { BaseArticle } from '../../common/BaseArticle/BaseArticle';
import { getNews, Post } from '@app/api/news.api';
import { BaseSelect } from '../../common/selects/BaseSelect/BaseSelect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProcessingLocationsByIdResponse, doUpdateProcessingLocations, getProcessingLocationsById } from '@app/api/processingLocations.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseCheckbox } from '../../common/BaseCheckbox/BaseCheckbox';
import { getIPV4 } from '@app/api/getIPAddress.api';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { getProcessings } from '@app/api/processings.api';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

const initialProcessingLocation = {
  name: '',
  updatedByUserId: 'abc',
  processingId:'',
  updatedByIpAddress: '127.0.0',
  publish: true,
};

export const ProcessingLocationsEdit: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: ProcessingLocationsByIdResponse; loading: boolean }>({
    data:{} as ProcessingLocationsByIdResponse,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [isPreview, setPreview] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [options, setOptions] = useState<any[]>([]); 
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(initialProcessingLocation);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleOnchange = (text: any, input: string) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  useEffect(() => {
    setLoading(true);
    getProcessingLocationData();
  }, [isMounted]);

  const getProcessingLocationData = async () => {
    try {
      // Make a GET request using axios
      const response = await getProcessingLocationsById(id!);
      getProcessingByList();
      // if (isMounted.current) {
      const responseData = response!.data;

      // Destructure values from the response data
      const { name, publish,processingId } = responseData;

      // Check if title and remark have valid values

      setInputs({
        name: name,
        publish: publish,
        updatedByIpAddress:'',
        updatedByUserId:'',
        processingId:processingId
        
      });

      form.setFieldsValue({
        name: name,
        publish: publish,
        processingId:processingId
      });
   
      setTableData({ data: responseData, loading: false });
      // }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const testCheckBox = async () => {
    console.log("hit1");
    form.setFieldsValue({
      publish: true,
    });
  };

  const checkValidator = async () => {
    if (inputs.name !== '') {
    
      updateProcessingLocations();
    }
  };

  const getProcessingByList = async () => {
    try {
      const response = await getProcessings();

      const responseData = response!.data;
      const tempoptions = [];
      for (let i = 0; i < response?.data.length!; i++) {
        tempoptions.push({
          value: response?.data[i].id,
          label: response?.data[i].name,
        });
      }
      setOptions(tempoptions);
  
      // Check if title and remark have valid values

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateProcessingLocations = async () => {
    try {
        inputs.updatedByIpAddress = await getIPV4();
        inputs.updatedByUserId ='';

      const response = await doUpdateProcessingLocations(id!,{name:inputs.name,publish:inputs.publish,processingId:inputs.processingId, updatedByUserId: inputs.updatedByUserId ,updatedByIpAddress:inputs.updatedByIpAddress,id:id!});

      if (response!.result.success) {
        notificationController.success({ message: 'Update processing locations success' });
        navigate('/processings/processingLocationlist');
      }
    } catch (error) {
      console.log('Error add data: ', error);
    }
  };

  const ProcessingLocationInfo = (
    <S.Card id="basic-table" padding="1.25rem 1.25rem 0">
    <BaseButtonsForm 
    form={form} 
    isFieldsChanged={isFieldsChanged}
    name="userForm"
    loading={isLoading}
    layout="vertical">
      <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
      
        <BaseCol xs={24} md={24}>
          <BaseButtonsForm.Item
          name="name"
          label={t('processingLocations.name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <BaseInput onChange={(e) => handleOnchange(e.target.value, 'name')} />
          </BaseButtonsForm.Item>
        </BaseCol>
        <BaseCol xs={24} md={24}>
                  <BaseButtonsForm.Item name="processingId" label={t('processingLocations.formTarget')}>
                    <BaseSelect
                      style={{ width: '100%' }}
                      placeholder="Select Processing"
                      onChange={(val) => handleOnchange(val, 'processingId')}
                      options={options}
                    />
                  </BaseButtonsForm.Item>
                </BaseCol>
        <BaseCol xs={24} md={24}>
          <BaseButtonsForm.Item name="publish" label={t('processingLocations.publish')}>
            <BaseCheckbox
              checked={inputs.publish}
              
              onChange={(e) => handleOnchange(e.target.checked, 'publish')}
            >
              {t('processingLocations.publish')}
            </BaseCheckbox>
          </BaseButtonsForm.Item>
        </BaseCol>

      </BaseRow>
      
      <BaseButtonsForm.Item>
        <BaseSpace style={{ float: 'right' }}>
          <BaseButton type="primary" onClick={() => checkValidator()}>
          {t('common.save')}
          </BaseButton>
        </BaseSpace>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  </S.Card>
  )

  return (
    <>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} md={24} xl={24}>

        <S.Card title={t('processingLocations.editProcessingLocations')}>
          <BaseTabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: (
                  <span>
                    <AppleOutlined />
                    {t('processingLocations.info')} 
                  </span>
                ),
                children: ProcessingLocationInfo,
              }
              
            ]}
          />
        </S.Card>

        </BaseCol>
      </BaseRow>
    </>
  );
};
