import React from 'react';
import * as S from './References.styles';

const currentYear = new Date().getFullYear();

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        Copyright &copy; {currentYear} e-Mega Integration Platform. All rights reserved.
      </S.Text>
    </S.ReferencesWrapper>
  );
};
