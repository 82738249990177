import { ReactNode } from 'react';
import { BaseButton } from '../BaseButton/BaseButton';
import { BasePopover } from '../BasePopover/BasePopover';
import { BaseSpace } from '../BaseSpace/BaseSpace';
import * as S from './BaseDelete.styles';

interface BaseDelete {
  id: string;
  open: boolean;

  children: ReactNode;
}

const DeleteTitle = () => {
  return (
    <BaseSpace>
      <S.WarningIcon />
      <S.Title>{'Sure to delete'}</S.Title>
    </BaseSpace>
  );
};

const CustomPopoverContent = ({ onDelete, onCancel  }:any) => (
  <BaseSpace>
    <BaseButton type="default" danger onClick={() => onDelete()}>
      {'Yes'}
    </BaseButton>
    <BaseButton type="ghost" onClick={() => onCancel()}>
      {'Cancel'}
    </BaseButton>
  </BaseSpace>
);

const BaseDelete = ({onChange, onDelete, onCancel, open, children } :any) => {
  return (
    <BasePopover
      content={<CustomPopoverContent onCancel={onCancel} onDelete={onDelete} />}
      open={open}
      onOpenChange={onChange}
      trigger={'click'}
      title={DeleteTitle}
    >
      {children}
    </BasePopover>
  );
};

export default BaseDelete;
