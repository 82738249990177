import React, { useState, useEffect } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';

import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import * as S from './ProcessingLocations.styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';

import { BaseSpace } from '../../common/BaseSpace/BaseSpace';

import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { BaseCheckbox } from '../../common/BaseCheckbox/BaseCheckbox';
import { doAddProcessingLocations } from '@app/api/processingLocations.api';
import { getProcessings } from '@app/api/processings.api';
import { getIPV4 } from '@app/api/getIPAddress.api';
import { useNavigate } from 'react-router-dom';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { useMounted } from '@app/hooks/useMounted';

const initialProcessingLocations = {
  name: '',
  publish: true,
  processingId:'',
  createdByIpAddress:'127.0.1',
  createdByUserId:'abc'
};

export const ProcessingLocationsAdd: React.FC = () => {
  const { t } = useTranslation();
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isPreview, setPreview] = useState(false);
  const { isMounted } = useMounted();
  const [inputs, setInputs] = useState(initialProcessingLocations);
  const [options, setOptions] = useState<any[]>([]); 
  const [loaded, setLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleOnchange = (text: any, input: string) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  const submitProcessingLocationForm = async () => {
    try {
        inputs.createdByIpAddress = await getIPV4();
        setLoaded(true);
        
        const response = await doAddProcessingLocations(inputs);

      if (response!.result.success) {
        notificationController.success({ message: 'Added new data' });
        navigate('/processings/processingLocationlist');
      }
    } catch (error) {
      console.error('Error add data:', error);
    }
  };
  useEffect(() => {
    getProcessingByList();
  }, [isMounted]);

  const getProcessingByList = async () => {
    try {
      const response = await getProcessings();

      const responseData = response!.data;
      const tempoptions = [];
      for (let i = 0; i < response?.data.length!; i++) {
        tempoptions.push({
          value: response?.data[i].id,
          label: response?.data[i].name,
        });
      }
      setOptions(tempoptions);
  
      // Check if title and remark have valid values

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const currentDate = moment(Date.now()).format('YYYY-MM-DD');

  return (
    <>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} md={24} xl={10}>
          <S.Card id="basic-table" padding="1.25rem 1.25rem 0">
            <BaseButtonsForm form={form} isFieldsChanged={isFieldsChanged} layout="vertical" name="addProcessingLocationForm">
              <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
                <BaseCol span={24}>
                  <BaseButtonsForm.Item>
                    <BaseButtonsForm.Title>{t('processingLocations.addProcessingLocations')}</BaseButtonsForm.Title>
                  </BaseButtonsForm.Item>
                </BaseCol>

                <BaseCol xs={24} md={24}>
                  <BaseButtonsForm.Item
                    name="name"
                    label={t('processingLocations.name')}
                    rules={[{ required: true, message: t('common.requiredField') }]}
                  >
                    <BaseInput onChange={(e) => handleOnchange(e.target.value, 'name')} />
                  </BaseButtonsForm.Item>
                </BaseCol>
                <BaseCol xs={24} md={24}>
                  <BaseButtonsForm.Item name="processingId" label={t('processingLocations.formTarget')}>
                    <BaseSelect
                      style={{ width: '100%' }}
                      placeholder="Select Processing"
                      onChange={(val) => handleOnchange(val, 'processingId')}
                      options={options}
                    />
                  </BaseButtonsForm.Item>
                </BaseCol>
                <BaseCol xs={24} md={24}>
                  <BaseButtonsForm.Item name="publish" label={t('processingLocations.publish')}>
                    <BaseCheckbox
                      defaultChecked={inputs.publish}
                      onChange={(e) => handleOnchange(e.target.checked, 'publish')}
                    >
                      {t('processingLocations.publish')}
                    </BaseCheckbox>
                  </BaseButtonsForm.Item>
                </BaseCol>

              </BaseRow>
              <BaseButtonsForm.Item>
                <BaseSpace style={{ float: 'right' }}>
               
                  <BaseButton type="primary" onClick={() => submitProcessingLocationForm()} >
                    {t('common.save')}
                  </BaseButton>
                </BaseSpace>
              </BaseButtonsForm.Item>
            </BaseButtonsForm>
          </S.Card>
        </BaseCol>
      </BaseRow>
    </>
  );
};
