import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import * as S from '../../tables/Tables/Tables.styles';
import { BaseModal } from '../../common/BaseModal/BaseModal';
import { BaseForm } from '../../common/forms/BaseForm/BaseForm';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ProcessingLocationsResponse, doDeleteProcessingLocation, getProcessingLocationsByPaging, getProcessingLocations } from '@app/api/processingLocations.api';
import BaseDelete from '../../common/BaseDelete/BaseDelete';
import { getIPV4 } from '@app/api/getIPAddress.api';
import moment from 'moment';
import { getProcessings } from '@app/api/processings.api';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total:12
};

const initSearchValue = {
  name: '',
  processingId:''
};
interface ProcessingLocationsTable {
  _id:string;
  id: string;
  processingName: string;
  name: string;
  publish: string;

}
const deleteProcessingLocationsS = {

  deletedByIpAddress:'127.0.1',
  deletedByUserId:''
};
export const ProcessingLocationsList: React.FC = () => {
  const [tableData, setTableData] = useState<{ data?: ProcessingLocationsResponse[];  pagination: Pagination; loading: boolean }>({
    data: [],
    loading: false,
    pagination: initialPagination,

  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [inputs] = useState(deleteProcessingLocationsS);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]); 
  const [form] = BaseForm.useForm();
  const [search, setSearch] = useState(initSearchValue);
  const [visibleIndex, setVisibleIndex] = useState(0);

  const handlePopoverVisibleChange = (index:number) => {
    setVisibleIndex(index + 1);
  };

  const handleCancel = () => {
    setVisibleIndex(0);
  };

  const getProcessingLocations = async (pageSize:number,current:number,name:string,processingId:string) => {
    try {
      // Make a GET request using axios

      const response = await getProcessingLocationsByPaging(pageSize,current,name,processingId).then((res) => {
        console.log(res);
        if (isMounted.current) {
          setTableData({ data: res?.data.data,pagination:{current:res?.data.currentPageNumber,pageSize:res?.data.pageSize,total:res?.data.totalRecords}, loading: false });
        }
      });
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getProcessingLocations(pagination.pageSize!,pagination.current!,search.name,search.processingId);
      getProcessingByList();
   
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [isMounted]);

  const showProcessingLocationsModal = () => {
    setOpen(true);
  };

  const hideProcessingLocationsModal = () => {
    setOpen(false);
  };
  const deleteProcessingLocations = async (id: string) => {
    console.log(id);
    try {
      inputs.deletedByIpAddress = await getIPV4();
      const response = await doDeleteProcessingLocation(id,inputs);

      if (isMounted.current && response!.result.success) {
        handleDeleteRow(id);
        notificationController.error({ message:  t('common.delete') });
      }
    } catch (error) {
      console.error('Error add data:', error);
    }
  };

  const getProcessingByList = async () => {
    try {
      const response = await getProcessings();

      const responseData = response!.data;
      const tempoptions = [];
      for (let i = 0; i < response?.data.length!; i++) {
        tempoptions.push({
          value: response?.data[i].id,
          label: response?.data[i].name,
        });
      }
      setOptions(tempoptions);
  
      // Check if title and remark have valid values

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteRow = (rowId: string) => {
    const newData = [...tableData.data!];
    const index = tableData.data!.findIndex((item) => item.id == rowId);

    newData.splice(index, 1);

    setTableData({ ...tableData, data: newData });
  };
  const handleOnSearch = (text: any, input: string) => {
    if (text.length > 0) {
      setSearch((prevState) => ({ ...prevState, [input]: text }));
    } else {
      clearSearch();
    }
  };

  const searchProcessingLocations = () => {
 
    if (search.name !== null || search.name !== '') {
      // setTableData({ ...tableData, loading: true });

      getProcessingLocations(initialPagination.pageSize!,initialPagination.current!,search.name,search.processingId);
      // console.log('search title', tableData.data);
    }
  };

  const clearSearch = () => {
    form.resetFields();

    setSearch({ name: '',processingId:'' });
    
    setTableData({ ...tableData, data: tableData.data });
  };

  const columns: ColumnsType<ProcessingLocationsTable> = [
    {
      title:  t('processingLocations.table-processingName'),
      key:'processingName',
      dataIndex: 'processingName',
      
    },
    {
        title:  t('processingLocations.table-name'),
        key:'name',
        dataIndex: 'name',
        
      },
  
    {
      title:  t('processingLocations.table-publish'),
      key:'publish',
      dataIndex: 'publish',
      render: (item) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol key={item}>
            <Status color={defineColorByPriority(item ? 1 : 3)} text={item ?  t('processingLocations.publish-active') :  t('processingLocations.publish-inactive')} />
          </BaseCol>
        </BaseRow>
      ),
    },
    {
      title: t('tables.actions'),
      key:'id',
      dataIndex: 'id',
      width: '15%',
      render: (text: string, record: ProcessingLocationsTable, index: number) => {
       
        return (
          <BaseSpace>
            <BaseButton type="ghost">
              <Link to={`/processings/processingLocations-edit/${record.id}`}>
                {t('processingLocations.edit')}
              </Link>
            </BaseButton>
            <BaseDelete
              open={visibleIndex === index + 1 ? true : false}
              onChange={() => handlePopoverVisibleChange(index)}
              onCancel={handleCancel}
              onDelete={() => deleteProcessingLocations(record.id)}
            >
              <BaseButton type="default" danger>
                {t('tables.delete')}
              </BaseButton>
            </BaseDelete>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="basic-table" title={t('processingLocations.listTitle')} padding="1.25rem 1.25rem 0">
          <BaseForm form={form} layout="vertical" name="ProcessingLocationsSearchForm">
            <BaseRow gutter={10}>
              <BaseCol xs={12} md={12} xl={6}>
                <BaseForm.Item
                  name={t('processingLocations.name') }
                  label={t('processingLocations.name') }
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <BaseInput onChange={(event) => handleOnSearch(event.target.value, 'name')} />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xs={12} md={12} xl={6}>
                  <BaseButtonsForm.Item name="processingId" label={t('processingLocations.formTarget')}>
                    <BaseSelect
                      style={{ width: '100%' }}
                      placeholder="Select Processing"
                      onChange={(val) => handleOnSearch(val, 'processingId')}
                      options={options}
                    />
                  </BaseButtonsForm.Item>
                </BaseCol>

              <BaseCol xs={24} md={24} xl={24}>
                <BaseSpace style={{ float: 'right' }}>
                  <BaseForm.Item>
                    <BaseButton type="ghost" style={{ marginBottom: 10 }} onClick={() => clearSearch()}>
                      {'Clear'}
                    </BaseButton>
                  </BaseForm.Item>
                  <BaseForm.Item>
                    <BaseButton type="primary" style={{ marginBottom: 10 }} onClick={() => searchProcessingLocations()}>
                      {'Search'}
                    </BaseButton>
                  </BaseForm.Item>
                </BaseSpace>
              </BaseCol>
            </BaseRow>
          </BaseForm>
          <BaseSpace direction="horizontal">
            <BaseButton type="primary" style={{ marginBottom: 10 }} onClick={() => setOpen(!open)}>
              <Link to={'/processings/processingLocations-add'}> {t('processingLocations.addProcessingLocations')}</Link>
            </BaseButton>
          </BaseSpace>

          <BaseTable
            rowKey='id'
            columns={columns}
            dataSource={tableData.data}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            bordered
            pagination={{ defaultPageSize: 10, total:tableData.pagination.total,
              onChange: (page, pageSize) => {
               
                fetch({pageSize,current:page});
              },
              showSizeChanger: true,position: ["bottomCenter"]}}
          />
        </S.Card>
      </S.TablesWrapper>
      
    </>
  );
};
