import { BaseResponse } from '@app/entities/BaseResponse';
import { commonHttpApi } from './commonHttp.api';
import { ApiError } from './ApiError';
import i18n from '@app/i18n';
import { IList } from '@app/entities/IListResponse';

export interface UserRequest {
    userName: string;
    employeeId: string;
    password:string;
    confirmPassword:string;
    firstName:string;
    lastName:string;
    email:string;
    dateOfJoinR:string;
    userRole:string;
    active:boolean;
    createdByIpAddress:string;
  }
  
  export interface UpdateUser {
    id: string;
    userName: string;
    employeeId: string;
    firstName:string;
    lastName:string;
    email:string;
    dateOfJoinR:string;
    userRole:string;
    active:boolean;
    updatedByUserId:string;
    updatedByIpAddress:string;
  }

  export interface UpdateUserPassword {
    id: string;
    password:string;
    ipAddress:string;
  }

  export interface DeleteUser {
    deletedByIpAddress:string;
    deletedByUserId:number;
  }

  export interface UserResponse {
    id: string;
    userName: string;
    employeeId: string;
    firstName:string;
    lastName:string;
    email:string;
    dateOfJoinR:string;
    userRole:number;
    active:boolean;
    ipAddress:string;
    createdByUser:string;
    updatedByUser:string;
    createdipAddress:string;
    updatedipAddress:string;
  }

  export interface UserByIdResponse {
    id: string;
    userName: string;
    employeeId: string;
    firstName:string;
    lastName:string;
    email:string;
    dateOfJoin:Date;

    userRole:string;
    active:boolean;
   
  }

  export const getUserByPaging = async (pageSize:number,pageNumber:number,employeeId:string): Promise<BaseResponse<IList<UserResponse>> | undefined> => {
    let result: BaseResponse<IList<UserResponse>> | undefined = undefined;

    await commonHttpApi
      .get<BaseResponse<IList<UserResponse>>>('Users/ByPaging',{ params: { pageSize: pageSize,pageNumber:pageNumber,employeeId:employeeId } })
      .then(({ data }) => {
     
        result = data;
        
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const getUserById = async (userPayload: string): Promise<BaseResponse<UserByIdResponse> | undefined> => {
    let result: BaseResponse<UserByIdResponse> | undefined = undefined;
    await commonHttpApi
      .get<BaseResponse<UserByIdResponse>>('Users/' + userPayload )
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status == 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const getUsers = async (): Promise<BaseResponse<UserResponse> | undefined> => {
    let result: BaseResponse<UserResponse> | undefined = undefined;
    await commonHttpApi
      .get<BaseResponse<UserResponse>>('Users')
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const doAddUsers = async (
    userPayload: UserRequest,
  ): Promise<BaseResponse<UserResponse> | undefined> => {
    let result: BaseResponse<UserResponse> | undefined = undefined;

    await commonHttpApi
      .post<BaseResponse<UserResponse>>('Users', { ...userPayload })
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {

        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const doUpdateUsers = async (
    userId: string,
    userPayload: UpdateUser,
  ): Promise<BaseResponse<UserResponse> | undefined> => {
    let result: BaseResponse<UserResponse> | undefined = undefined;

    await commonHttpApi
      .put<BaseResponse<UserResponse>>('Users/UpdateUsers/' + userId, {
        ...userPayload,
      })
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status == 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const doDeleteUser = async (
    userId: string,
    userPayload: DeleteUser,
  ): Promise<BaseResponse<UserResponse> | undefined> => {
    let result: BaseResponse<UserResponse> | undefined = undefined;
    await commonHttpApi
    .put<BaseResponse<UserResponse>>('Users/DeleteUser/' + userId, {
      ...userPayload,
    })
    .then(({ data }) => {
      result = data;
    })
    .catch((err) => {
      if (err.options) {
        if (err.options.status == 401) {
          switch (err.options.code) {
            case '1000002':
            case '1000013':
              throw new ApiError(i18n.t('login.loginError'));
          }
        }
      }

      throw new ApiError(i18n.t('serverError.main'));
    });

  return result;
  };
