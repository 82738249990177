import React, { useEffect, useState, useCallback } from 'react';
import { Pagination } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { InboxOutlined } from '@ant-design/icons';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { notificationController } from 'controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import * as S from '../../tables/Tables/Tables.styles';
import { BaseModal } from '../../common/BaseModal/BaseModal';
import { BaseForm } from '../../common/forms/BaseForm/BaseForm';
import { Select, Spin, Modal } from 'antd';
import BaseDelete from '../../common/BaseDelete/BaseDelete';
import { getIPV4 } from '@app/api/getIPAddress.api';
import { getItemTypes } from '@app/api/itemTypes.api';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 12,
};

const PcStockDetailTable = {
  itemColor: '',
  availableCartons: '',
};

const initSearchValue = {
  pitId: '',
  requestType: '',
};

const deletePcStockDetails = {
  deletedByIpAddress: '127.0.1',
  deletedByUserId: '1',
};

export interface TotalCarton {
  activeCarton: number;
  damagedCarton: number;
}

export interface PcStockDetail {
  itemColor: string;
  availableCartons: number;
}

export const PcStockDetailsList: React.FC = () => {
  const [tableData, setTableData] = useState<{ data?: PcStockDetail[]; pagination: Pagination; loading: boolean }>({
    data: [],
    loading: false,
    pagination: initialPagination,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [inputs] = useState(deletePcStockDetails);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([
    { label: 'Cards', value: 'Cards' },
    { label: 'Seals', value: 'Seals' },
    { label: 'Carriers', value: 'Carriers' },
    { label: 'Baskets', value: 'Baskets' },
  ]);
  const { Option } = Select;
  const [form] = BaseForm.useForm();
  const [search, setSearch] = useState(initSearchValue);
  const [printing, setPrinting] = useState(false);
  const hardcodedData: PcStockDetail[] = [
    { itemColor: 'BLUE', availableCartons: 18 },
    { itemColor: 'GREEN PSC', availableCartons: 18 },
    { itemColor: 'RED', availableCartons: 18 },
    { itemColor: 'PURPLE PSC', availableCartons: 18 },
  ];
  const fakeValue: TotalCarton[] = [{ activeCarton: 145, damagedCarton: 30 }];

  const handleRefresh = () => {
    fetch(tableData.pagination);
  };

  const handlePrint = () => {
    setPrinting(true);
    setTimeout(() => setPrinting(false), 1000);
  };

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      setTimeout(() => {
        const newData = hardcodedData;
        setTableData((tableData) => ({ ...tableData, data: newData, loading: false }));
      }, 500);
    },
    [isMounted],
  );

  const columns: ColumnsType<typeof PcStockDetailTable> = [
    {
      title: t('pcStockDetails.table-itemColor'),
      key: 'itemColor',
      dataIndex: 'itemColor',
    },
    {
      title: t('pcStockDetails.table-availableCartons'),
      key: 'availableCartons',
      dataIndex: 'availableCartons',
    },
  ];

  return (
    <>
      <BaseRow gutter={10}>
        <BaseCol xs={24} md={12} xl={12}>
          <BaseSpace style={{ float: 'left', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: '#BBE6F1',
                padding: '20px',
                borderRadius: '5px',
                width: '575px',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <h3 style={{ margin: 0 }}>Active Carton</h3>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    backgroundColor: 'pcite',
                    borderRadius: '50%',
                    width: '70px',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}
                >
                  <InboxOutlined style={{ color: '#1890ff', fontSize: '40px' }} />
                </div>
                <div style={{ fontSize: '40px' }}>{fakeValue[0].activeCarton}</div>
              </div>
            </div>
          </BaseSpace>
        </BaseCol>
        <BaseCol xs={24} md={12} xl={12}>
          <BaseSpace style={{ float: 'left', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: '#BBE6F1',
                padding: '20px',
                borderRadius: '5px',
                width: '575px',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <h3 style={{ margin: 0 }}>Damaged Carton</h3>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    backgroundColor: 'pcite',
                    borderRadius: '50%',
                    width: '70px',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}
                >
                  <InboxOutlined style={{ color: '#1890ff', fontSize: '40px' }} />
                </div>
                <div style={{ fontSize: '40px' }}>{fakeValue[0].damagedCarton}</div>
              </div>
            </div>
          </BaseSpace>
        </BaseCol>
      </BaseRow>
      <S.TablesWrapper>
        <S.Card id="basic-table" title={t('pcStockDetails.listTitle')} padding="1.25rem 1.25rem 0">
          <BaseForm form={form} layout="vertical" name="pcStockDetailsSearchForm">
            <BaseRow gutter={10}>
              <BaseCol xs={24} md={24} xl={24}>
                <div style={{ marginLeft: '150px', marginBottom: '20px' }}>
                  <span style={{ marginRight: '30px', fontSize: '20px' }}>Type of Items</span>
                  <Select defaultValue="Cards" style={{ width: '500px' }}>
                    {options.map((option, index) => (
                      <Option key={index} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </BaseCol>
            </BaseRow>
            <BaseRow gutter={10}>
              <BaseCol xs={24} md={24} xl={24}>
                <BaseSpace style={{ float: 'right', marginBottom: '40px' }}>
                  <BaseButton type="primary" onClick={handleRefresh} style={{ marginRight: '10px' }}>
                    {'Refresh'}
                  </BaseButton>
                </BaseSpace>
                <BaseSpace style={{ float: 'right', marginBottom: '40px' }}>
                  <BaseButton type="ghost" onClick={handlePrint} style={{ marginRight: '10px' }}>
                    {'Print'}
                    <Modal visible={printing} centered footer={null} closable={false} maskClosable={false}>
                      <div style={{ textAlign: 'center' }}>
                        <h2 style={{ marginBottom: '10px' }}>Waiting for printing!</h2>
                        <p>Please wait.</p>
                        <Spin />
                      </div>
                    </Modal>
                  </BaseButton>
                </BaseSpace>
              </BaseCol>
            </BaseRow>
          </BaseForm>

          <BaseTable
            rowKey="id"
            columns={columns}
            dataSource={hardcodedData}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            bordered
            pagination={{
              defaultPageSize: 10,
              total: hardcodedData.length,
              onChange: (page, pageSize) => {
                fetch({ pageSize, current: page });
              },
              showSizeChanger: true,
              position: ['bottomCenter'],
            }}
          />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
