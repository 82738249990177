import { BaseResponse } from '@app/entities/BaseResponse';
import { commonHttpApi } from './commonHttp.api';
import { ApiError } from './ApiError';
import i18n from '@app/i18n';
import { StringLiteral } from 'typescript';
import { IList } from '@app/entities/IListResponse';

export interface ProcessingsRequest {
    name: string;
    publish:boolean;
    createdByIpAddress:string;
  }
  
  export interface UpdateProcessings {
    id: string;
    name: string;
    publish:boolean;
    updatedByUserId:string;
    updatedByIpAddress:string;
  }

  export interface DeleteProcessings {
    deletedByIpAddress:string;
    deletedByUserId:string;
  }

  export interface ProcessingsResponse {
    id: string;
    name: string;
    publish:boolean;
    createdByIpAddress:string;
    createdByProcessings:string;
    updatedByProcessings:string;
    updatedipAddress:string;
  }

  export interface ProcessingsByIdResponse {
    id: string;
    name: string;
    publish:boolean;
  }

  export const getProcessingsByPaging = async (pageSize:number,pageNumber:number,name:string): Promise<BaseResponse<IList<ProcessingsResponse>> | undefined> => {
    let result: BaseResponse<IList<ProcessingsResponse>> | undefined = undefined;
    
    await commonHttpApi
      .get<BaseResponse<IList<ProcessingsResponse>>>('Processings/ByPaging',{ params: { pageSize: pageSize,pageNumber:pageNumber,name:name } })
      .then(({ data }) => {
        
        result = data;
        
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const getProcessings = async (): Promise<BaseResponse<ProcessingsResponse[]> | undefined> => {
    let result: BaseResponse<ProcessingsResponse[]> | undefined = undefined;

    await commonHttpApi
      .get<BaseResponse<ProcessingsResponse[]>>('Processings')
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const getProcessingsById = async (ProcessingsPayload: string): Promise<BaseResponse<ProcessingsByIdResponse> | undefined> => {
    let result: BaseResponse<ProcessingsByIdResponse> | undefined = undefined;
    await commonHttpApi
      .get<BaseResponse<ProcessingsByIdResponse>>('Processings/' + ProcessingsPayload)
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status == 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

export const doAddProcessings = async (
    ProcessingsPayload: ProcessingsRequest,
  ): Promise<BaseResponse<ProcessingsResponse> | undefined> => {
    let result: BaseResponse<ProcessingsResponse> | undefined = undefined;
  
    await commonHttpApi
      .post<BaseResponse<ProcessingsResponse>>('Processings', { ...ProcessingsPayload })
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const doUpdateProcessings = async (
    ProcessingsId: string,
    ProcessingsPayload: UpdateProcessings,
  ): Promise<BaseResponse<ProcessingsResponse> | undefined> => {
    let result: BaseResponse<ProcessingsResponse> | undefined = undefined;

    await commonHttpApi
      .put<BaseResponse<ProcessingsResponse>>('Processings/UpdateProcessings/' + ProcessingsId, {
        ...ProcessingsPayload,
      })
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status == 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const doDeleteProcessings = async (
    ProcessingsId: string,
    ProcessingsPayload: DeleteProcessings,
  ): Promise<BaseResponse<ProcessingsResponse> | undefined> => {
    let result: BaseResponse<ProcessingsResponse> | undefined = undefined;
    await commonHttpApi
    .put<BaseResponse<ProcessingsResponse>>('Processings/DeleteProcessings/' + ProcessingsId, {
      ...ProcessingsPayload,
    })
    .then(({ data }) => {
      result = data;
    })
    .catch((err) => {
      if (err.options) {
        if (err.options.status == 401) {
          switch (err.options.code) {
            case '1000002':
            case '1000013':
              throw new ApiError(i18n.t('login.loginError'));
          }
        }
      }

      throw new ApiError(i18n.t('serverError.main'));
    });

  return result;
  };
  