import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import * as S from '../../tables/Tables/Tables.styles';
import { BaseForm } from '../../common/forms/BaseForm/BaseForm';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { Link } from 'react-router-dom';
import { getProcessingsByPaging, getProcessings,doDeleteProcessings, ProcessingsResponse } from '@app/api/processings.api';
import BaseDelete from '../../common/BaseDelete/BaseDelete';
import { getIPV4 } from '@app/api/getIPAddress.api';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total:12
};

const initSearchValue = {
  name: '',
};

const deleteProcessings = {

    deletedByIpAddress:'127.0.1',
    deletedByUserId:'1'
  };

export const ProcessingsList: React.FC = () => {
  const [tableData, setTableData] = useState<{ data?: ProcessingsResponse[];  pagination: Pagination; loading: boolean }>({
    data:[],

    loading: false,
    pagination: initialPagination,

  });
  const [isLoading, setLoading] = useState(false);
  const [inputs] = useState(deleteProcessings);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [open, setOpen] = useState(false);
  const [form] = BaseForm.useForm();
  const [search, setSearch] = useState(initSearchValue);
  const [visibleIndex, setVisibleIndex] = useState(0);

  const handlePopoverVisibleChange = (index:number) => {
    setVisibleIndex(index + 1);
  };

  const handleCancel = () => {
    setVisibleIndex(0);
  };

  const getProcessings = async (pageSize:number,current:number,name:string) => {
    try {
      // Make a GET request using axios
      console.log("hit1");
      const response = await getProcessingsByPaging(pageSize,current,name).then((res) => {

        if (isMounted.current) {
          setTableData({ data:res?.data.data,pagination:{current:res?.data.currentPageNumber,pageSize:res?.data.pageSize,total:res?.data.totalRecords}, loading: false });
        }
      });
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getProcessings(pagination.pageSize!,pagination.current!,search.name);
   
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [isMounted]);

  const handleOnSearch = (text: any, input: string) => {
    if (text.length > 0) {
      setSearch((prevState) => ({ ...prevState, [input]: text }));
    } else {
      clearSearch();
    }
  };

  const deleteCardColros = async (id: string) => {
  
    try {
      inputs.deletedByIpAddress = await getIPV4();
      const response = await doDeleteProcessings(id,inputs);

      if (isMounted.current && response!.result.success) {
        handleDeleteRow(id);
        notificationController.error({ message:  t('common.delete') });
      }
    } catch (error) {
      console.error('Error add data:', error);
    }
  };
  const handleDeleteRow = (rowId: string) => {
    const newData = [...tableData.data!];
    const index = tableData.data!.findIndex((item) => item.id == rowId);

    newData.splice(index, 1);

    setTableData({ ...tableData, data: newData });
  };
  const searchProcessings = () => {

    if (search.name !== null || search.name !== '') {

      getProcessings(initialPagination.pageSize!,initialPagination.current!,search.name);

    }
  };

  const clearSearch = () => {
    form.resetFields();
    setSearch({ name: '' });
    setTableData({ ...tableData, data: tableData.data });
  };

  const columns: ColumnsType<ProcessingsResponse> = [
    {
      title:  t('processings.table-name'),
      key:'name',
      dataIndex: 'name',
      
    },
 
    {
      title:  t('processings.table-publish'),
      key:'publish',
      dataIndex: 'publish',
      render: (item) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol key={item}>
            <Status color={defineColorByPriority(item ? 1 : 3)} text={item ?  t('processings.publish-active') :  t('processings.publish-inactive')} />
          </BaseCol>
        </BaseRow>
      ),
    },

    {
      title: t('tables.actions'),
      key:'id',
      dataIndex: 'id',
      width: '15%',
      render: (text: string, record: ProcessingsResponse, index: number) => {
  
        return (
          <BaseSpace>
            <BaseButton type="ghost">
              <Link to={`/processings/processings-edit/${record.id}`}>
                {t('processings.edit')}
              </Link>
            </BaseButton>
            <BaseDelete
              open={visibleIndex === index + 1 ? true : false}
              onChange={() => handlePopoverVisibleChange(index)}
              onCancel={handleCancel}
              onDelete={() => deleteCardColros(record.id)}
            >
              <BaseButton type="default" danger>
                {t('tables.delete')}
              </BaseButton>
            </BaseDelete>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="basic-table" title={t('processings.listTitle')} padding="1.25rem 1.25rem 0">
          <BaseForm form={form} layout="vertical" name="ProcessingsSearchForm">
            <BaseRow gutter={10}>
              <BaseCol xs={24} md={24} xl={12}>
                <BaseForm.Item
                  name={t('processings.name') }
                  label={t('processings.name') }
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <BaseInput onChange={(event) => handleOnSearch(event.target.value, 'name')} />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xs={24} md={24} xl={24}>
                <BaseSpace style={{ float: 'right' }}>
                  <BaseForm.Item>
                    <BaseButton type="ghost" style={{ marginBottom: 10 }} onClick={() => clearSearch()}>
                      {'Clear'}
                    </BaseButton>
                  </BaseForm.Item>
                  <BaseForm.Item>
                    <BaseButton type="primary" style={{ marginBottom: 10 }} onClick={() => searchProcessings()}>
                      {'Search'}
                    </BaseButton>
                  </BaseForm.Item>
                </BaseSpace>
              </BaseCol>    
            </BaseRow>
          </BaseForm>
          <BaseSpace direction="horizontal">
            <BaseButton type="primary" style={{ marginBottom: 10 }} onClick={() => setOpen(!open)}>
              <Link to={'/processings/processings-add'} > {t('processings.addProcessings')}</Link>
            </BaseButton>
          </BaseSpace>

          <BaseTable
            rowKey='id'
            columns={columns}
            dataSource={tableData.data}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            bordered
            pagination={{ defaultPageSize: 10, total:tableData.pagination.total,
              onChange: (page, pageSize) => {
               
                fetch({pageSize,current:page});
              },
              showSizeChanger: true,position: ["bottomCenter"]}}
          />
        </S.Card>
      </S.TablesWrapper>
      
    </>
  );
};
