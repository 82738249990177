import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';
import MainDashboardPage from '@app/pages/DashboardPages/MainDashboardPage';
import { PcStockDetailsList } from '../eMIPs/PcStockDetails/PcStockDetailsList';
import { ProcessingsList } from '../eMIPs/processings/ProcessingsList';
import { ProcessingsAdd } from '../eMIPs/processings/ProcessingsAdd';
import { ProcessingsEdit } from '../eMIPs/processings/ProcessingsEdit';
import { ProcessingLocationsList } from '../eMIPs/processingLocations/ProcessingLocationsList';
import { ProcessingLocationsAdd } from '../eMIPs/processingLocations/ProcessingLocationsAdd';
import { ProcessingLocationsEdit } from '../eMIPs/processingLocations/ProcessingLocationsEdit';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const UserListPage = React.lazy(() => import('@app/pages/Users/UserListPage'));
const UserAddPage = React.lazy(() => import('@app/pages/Users/UserAddPage'));
const UserEditPage = React.lazy(() => import('@app/pages/Users/UserEditPage'))
const UserRolesListPage = React.lazy(() => import('@app/pages/UserRoles/UserRolesListPage'));
const UserRolesAddPage = React.lazy(() => import('@app/pages/UserRoles/UserRolesAddPage'));
const UserRolesEditPage = React.lazy(() => import('@app/pages/UserRoles/UserRolesEditPage'));
const SealColorsListPage = React.lazy(() => import('@app/pages/SealColors/SealColorsListPage'));
const SealColorsAddPage = React.lazy(() => import('@app/pages/SealColors/SealColorsAddPage'));
const SealColorsEditPage = React.lazy(() => import('@app/pages/SealColors/SealColorsEditPage'));
const ItemTypesListPage = React.lazy(() => import('@app/pages/ItemTypes/ItemTypesListPage'));
const ItemTypesAddPage = React.lazy(() => import('@app/pages/ItemTypes/ItemTypesAddPage'));
const ItemTypesEditPage = React.lazy(() => import('@app/pages/ItemTypes/ItemTypesEditPage'));
const PitTableMappingListPage = React.lazy(() => import('@app/pages/PitTableMappings/PitTableMappingListPage'));

const LocalitiesListPage = React.lazy(() => import('@app/pages/Localities/LocalitiesListPage'));

const ItemMovementScanPersonnelsListPage = React.lazy(() => import('@app/pages/ItemMovementScanPersonnels/ItemMovementScanPersonnelListPage'));
const ItemMovementScanPersonnelsAddPage = React.lazy(() => import('@app/pages/ItemMovementScanPersonnels/ItemMovementScanPersonnelAddPage'));
const ItemMovementScanPersonnelsEditPage = React.lazy(() => import('@app/pages/ItemMovementScanPersonnels/ItemMovementScanPersonnelEditPage'));

const CardColorsListPage = React.lazy(() => import('@app/pages/CardColors/CardColorsListPage'));
const CardColorsAddPage = React.lazy(() => import('@app/pages/CardColors/CardColorsAddPage'));
const CardColorsEditPage = React.lazy(() => import('@app/pages/CardColors/CardColorsEditPage'));

const CardGamesListPage = React.lazy(() => import('@app/pages/CardGames/CardGameListPage'));
const CardGamesAddPage = React.lazy(() => import('@app/pages/CardGames/CardGameAddPage'));
const CardGamesEditPage = React.lazy(() => import('@app/pages/CardGames/CardGameEditPage'));

const CardTypesListPage = React.lazy(() => import('@app/pages/CardTypes/CardTypesListPage'));
const CardTypesAddPage = React.lazy(() => import('@app/pages/CardTypes/CardTypesAddPage'));
const CardTypesEditPage = React.lazy(() => import('@app/pages/CardTypes/CardTypesEditPage'));

const BasketItemsListPage = React.lazy(() => import('@app/pages/BasketItems/BasketItemsListPage'));
const BasketItemsAddPage = React.lazy(() => import('@app/pages/BasketItems/BasketItemsAddPage'));
const BasketItemsEditPage = React.lazy(() => import('@app/pages/BasketItems/BasketItemsEditPage'));

const WarehousesListPage = React.lazy(() => import('@app/pages/Warehouses/WarehousesListPage'));
const WarehousesAddPage = React.lazy(() => import('@app/pages/Warehouses/WarehousesAddPage'));
const WarehousesEditPage = React.lazy(() => import('@app/pages/Warehouses/WarehousesEditPage'));

const ImportFileListPage = React.lazy(() => import('@app/pages/importFiles/importFileListPage'));

const WarehouseLocationsListPage = React.lazy(() => import('@app/pages/WarehouseLocations/WarehouseLocationsListPage'));
const WarehouseLocationsAddPage = React.lazy(() => import('@app/pages/WarehouseLocations/WarehouseLocationsAddPage'));
const WarehouseLocationsEditPage = React.lazy(() => import('@app/pages/WarehouseLocations/WarehouseLocationsEditPage'));

const GamePitsListPage = React.lazy(() => import('@app/pages/GamePits/GamePitsListPage'));
const GamePitsAddPage = React.lazy(() => import('@app/pages/GamePits/GamePitsAddPage'));
const GamePitsEditPage = React.lazy(() => import('@app/pages/GamePits/GamePitsEditPage'));
const GamePitLocationsListPage = React.lazy(() => import('@app/pages/GamePitLocations/GamePitLocationsListPage'));
const GamePitLocationsAddPage = React.lazy(() => import('@app/pages/GamePitLocations/GamePitLocationsAddPage'));
const GamePitLocationsEditPage = React.lazy(() => import('@app/pages/GamePitLocations/GamePitLocationsEditPage'));
const GameTablesListPage = React.lazy(() => import('@app/pages/GameTables/GameTableListPage'));
const GameTablesAddPage = React.lazy(() => import('@app/pages/GameTables/GameTableAddPage'));
const GameTablesEditPage = React.lazy(() => import('@app/pages/GameTables/GameTableEditPage'));
const VendorsListPage = React.lazy(() => import('@app/pages/Vendors/VendorsListPage'));
const VendorsAddPage = React.lazy(() => import('@app/pages/Vendors/VendorsAddPage'));
const VendorsEditPage = React.lazy(() => import('@app/pages/Vendors/VendorsEditPage'));
const ImportFieldMappingsListPage = React.lazy(() => import('@app/pages/ImportFieldMappings/ImportFieldMappingsListPage'));
const ImportFieldMappingsAddPage = React.lazy(() => import('@app/pages/ImportFieldMappings/ImportFieldMappingsAddPage'));
const ImportFieldMappingsEditPage = React.lazy(() => import('@app/pages/ImportFieldMappings/ImportFieldMappingsEditPage'));
const WhAcknowledgeStocksPage = React.lazy(() => import('@app/pages/WhAcknowledgeStocks/WhAcknowledgeStockPage'));
const TestPage = React.lazy(() => import('@app/pages/TestPage/TestPage'));
const ImportHistoriesListPage = React.lazy(() => import('@app/pages/ImportHistories/ImportHistoriesListPage'));
const RequestCarriersOrBasketsListPage = React.lazy(() => import('@app/pages/RequestCarriersOrBaskets/RequestCarriersOrBasketsListPage'));
const NotificationPitToProcessListPage = React.lazy(() => import('@app/pages/NotificationPitToProcess/NotificationPitToProcessListPage'));
const NotificationPitToProcessEditPage = React.lazy(() => import('@app/pages/NotificationPitToProcess/NotificationPitToProcessEditPage'));
const PcPitDetailsListPage = React.lazy(() => import('@app/pages/PcPitDetails/PcPitDetailsListPage'));
const PcAcknowledgeStocksListPage = React.lazy(() => import('@app/pages/PcAcknowledgeStocks/PcAcknowledgeStocksListPage'));
const PcReuseCarriersListPage = React.lazy(() => import('@app/pages/PcReuseCarriers/PcReuseCarriersListPage'));
const PcSecurityCheckPointByCarriersListPage = React.lazy(() => import('@app/pages/PcSecurityCheckPointByCarriers/PcSecurityCheckPointByCarriersListPage'))
const WhStockDetailsListPage = React.lazy(() => import('@app/pages/WhStockDetails/WhStockDetailsListPage'));
const WhAcknowledgementHistoriesListPage = React.lazy(() => import('@app/pages/WhAcknowledgementHistories/WhAcknowledgementHistoriesListPage'));
const WhTransferStocksMainPage = React.lazy(() => import('@app/pages/WhTransferStocks/WhTransferStocksMainPage'));
const WhReturnStocksListPage = React.lazy(() => import('@app/pages/WhReturnStocks/WhReturnStocksListPage'));
const WhInvestigationRequestsListPage = React.lazy(() => import('@app/pages/WhInvestigationRequests/WhInvestigationRequestsListPage'));
const PrefixSuffixSettingsListPage = React.lazy(() => import('@app/pages/PrefixSuffixSettings/PrefixSuffixSettingsListPage'));
const PrefixSuffixSettingsAddPage = React.lazy(() => import('@app/pages/PrefixSuffixSettings/PrefixSuffixSettingsAddPage'));
const PrefixSuffixSettingsEditPage = React.lazy(() => import('@app/pages/PrefixSuffixSettings/PrefixSuffixSettingsEditPage'));

const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));1
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/nft-dashboard';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';
export const MAIN_DASHBOARD_PATH = '/';
const MainDashboard = withLoading(MainDashboardPage);
const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const UserList = withLoading(UserListPage);
const UserAdd = withLoading(UserAddPage);
const UserEdit = withLoading(UserEditPage);
const UserRolesList = withLoading(UserRolesListPage);
const UserRolesAdd = withLoading(UserRolesAddPage);
const UserRolesEdit = withLoading(UserRolesEditPage);
const SealColorsList = withLoading(SealColorsListPage);
const SealColorsAdd = withLoading(SealColorsAddPage);
const SealColorsEdit = withLoading(SealColorsEditPage);
const ItemTypesList = withLoading(ItemTypesListPage);
const ItemTypesAdd = withLoading(ItemTypesAddPage);
const ItemTypesEdit = withLoading(ItemTypesEditPage);

const LocalitiesList = withLoading(LocalitiesListPage);
const PitTableMappingList = withLoading(PitTableMappingListPage);

const ItemMovementScanPersonnelsList = withLoading(ItemMovementScanPersonnelsListPage);
const ItemMovementScanPersonnelsAdd = withLoading(ItemMovementScanPersonnelsAddPage);
const ItemMovementScanPersonnelsEdit = withLoading(ItemMovementScanPersonnelsEditPage);

const CardColorsList = withLoading(CardColorsListPage);
const CardColorsAdd = withLoading(CardColorsAddPage);
const CardColorsEdit = withLoading(CardColorsEditPage);

const CardGamesList = withLoading(CardGamesListPage);
const CardGamesAdd = withLoading(CardGamesAddPage);
const CardGamesEdit = withLoading(CardGamesEditPage);

const CardTypesList = withLoading(CardTypesListPage);
const CardTypesAdd = withLoading(CardTypesAddPage);
const CardTypesEdit = withLoading(CardTypesEditPage);

const BasketItemsList = withLoading(BasketItemsListPage);
const BasketItemsAdd = withLoading(BasketItemsAddPage);
const BasketItemsEdit = withLoading(BasketItemsEditPage);

const WarehousesList = withLoading(WarehousesListPage);
const WarehousesAdd = withLoading(WarehousesAddPage);
const WarehousesEdit = withLoading(WarehousesEditPage);

const ImportFilesList = withLoading(ImportFileListPage);

const WarehouseLocationsList = withLoading(WarehouseLocationsListPage);
const WarehouseLocationsAdd = withLoading(WarehouseLocationsAddPage);
const WarehouseLocationsEdit = withLoading(WarehouseLocationsEditPage);

const GamePitsList = withLoading(GamePitsListPage);
const GamePitsAdd = withLoading(GamePitsAddPage);
const GamePitsEdit = withLoading(GamePitsEditPage);
const GamePitLocationsList = withLoading(GamePitLocationsListPage);
const GamePitLocationsAdd = withLoading(GamePitLocationsAddPage);
const GamePitLocationsEdit = withLoading(GamePitLocationsEditPage);
const GameTablesList = withLoading(GameTablesListPage);
const GameTablesAdd = withLoading(GameTablesAddPage);
const GameTablesEdit = withLoading(GameTablesEditPage);
const VendorsList = withLoading(VendorsListPage);
const VendorsAdd = withLoading(VendorsAddPage);
const VendorsEdit = withLoading(VendorsEditPage);
const ImportFieldMappingsList = withLoading(ImportFieldMappingsListPage);
const ImportFieldMappingsAdd = withLoading(ImportFieldMappingsAddPage);
const ImportFieldMappingsEdit = withLoading(ImportFieldMappingsEditPage);
const WhAcknowledgeStocksList = withLoading(WhAcknowledgeStocksPage);
const TestPageList = withLoading(TestPage);
const PcPitDetailsList = withLoading(PcPitDetailsListPage);
const PcAcknowledgeStocksList = withLoading(PcAcknowledgeStocksListPage);
const PcReuseCarriersList = withLoading(PcReuseCarriersListPage);
const PcSecurityCheckPointByCarriersList = withLoading(PcSecurityCheckPointByCarriersListPage);
const ImportHistoriesList = withLoading(ImportHistoriesListPage);
const RequestCarriersOrBasketsList = withLoading(RequestCarriersOrBasketsListPage);
const NotificationPitToProcessList = withLoading(NotificationPitToProcessListPage);
const NotificationPitToProcessEdit = withLoading(NotificationPitToProcessEditPage);
const WhStockDetailsList = withLoading(WhStockDetailsListPage);
const WhAcknowledgementHistoriesList = withLoading(WhAcknowledgementHistoriesListPage);
const WhTransferStocksMain = withLoading(WhTransferStocksMainPage);
const WhReturnStocksList = withLoading(WhReturnStocksListPage);
const WhInvestigationRequestsList = withLoading(WhInvestigationRequestsListPage);
const PrefixSuffixSettingsList = withLoading(PrefixSuffixSettingsListPage);
const PrefixSuffixSettingsAdd = withLoading(PrefixSuffixSettingsAddPage);
const PrefixSuffixSettingsEdit = withLoading(PrefixSuffixSettingsEditPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={MAIN_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<MainDashboard />} />
          <Route path={NFT_DASHBOARD_PATH} element={<NftDashboard />} />
          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />
          <Route path="user">
            <Route path="userList" element={<UserList />} />
            <Route path="user-add" element={<UserAdd />} />
            <Route path="user-edit/:id" element={<UserEdit />} />
            <Route path="userRolesList" element={<UserRolesList />} />
            <Route path="userrole-add" element={<UserRolesAdd />} />
            <Route path="userrole-edit/:id" element={<UserRolesEdit />} />
          </Route>

          <Route path="localities">
          <Route path="list" element={<LocalitiesList />} />

          </Route>

          <Route path="warehouses">
          <Route path="list" element={<WarehousesList />} />
            <Route path="warehouses-add" element={<WarehousesAdd />} />
            <Route path="warehouses-edit/:id" element={<WarehousesEdit />} />
            <Route path="warehouseLocationlist" element={<WarehouseLocationsList />} />
            <Route path="warehouseLocations-add" element={<WarehouseLocationsAdd />} />
            <Route path="warehouseLocations-edit/:id" element={<WarehouseLocationsEdit />} />
          </Route>

          <Route path="localities">
          <Route path="list" element={<LocalitiesList />} />
          <Route path="pitHubMapping" element={<PitTableMappingList />} />
          </Route>

          <Route path="processings">
          <Route path="list" element={<ProcessingsList />} />
            <Route path="processings-add" element={<ProcessingsAdd />} />
            <Route path="processings-edit/:id" element={<ProcessingsEdit />} />
            <Route path="processingLocationlist" element={<ProcessingLocationsList />} />
            <Route path="processingLocations-add" element={<ProcessingLocationsAdd />} />
            <Route path="processingLocations-edit/:id" element={<ProcessingLocationsEdit />} />
          </Route>

          <Route path="gamePit">
          <Route path="list" element={<GamePitsList />} />
            <Route path="gamePit-add" element={<GamePitsAdd />} />
            <Route path="gamePit-edit/:id" element={<GamePitsEdit />} />
            <Route path="gamePitLocationlist" element={<GamePitLocationsList />} />
            <Route path="gamePitLocations-add" element={<GamePitLocationsAdd />} />
            <Route path="gamePitLocations-edit/:id" element={<GamePitLocationsEdit />} />
          </Route>

          <Route path="sealColors">
            <Route path="list" element={<SealColorsList />} />
            <Route path="sealColors-add" element={<SealColorsAdd />} />
            <Route path="sealColors-edit/:id" element={<SealColorsEdit />} />
          </Route>
          <Route path="cardColors">
            <Route path="list" element={<CardColorsList />} />
            <Route path="cardColors-add" element={<CardColorsAdd />} />
            <Route path="cardColors-edit/:id" element={<CardColorsEdit />} />
          </Route>
          
          <Route path="cardGames">
            <Route path="cardGame-list" element={<CardGamesList />} />
            <Route path="cardGame-add" element={<CardGamesAdd />} />
            <Route path="cardGame-edit/:id" element={<CardGamesEdit />} />
          </Route>

          <Route path="cardTypes">
            <Route path="list" element={<CardTypesList />} />
            <Route path="cardTypes-add" element={<CardTypesAdd />} />
            <Route path="cardTypes-edit/:id" element={<CardTypesEdit />} />
          </Route>

          <Route path="itemTypes">
            <Route path="list" element={<ItemTypesList />} />
            <Route path="itemTypes-add" element={<ItemTypesAdd />} />
            <Route path="itemTypes-edit/:id" element={<ItemTypesEdit />} />
          </Route>

          <Route path="itemMovementScanPersonnels">
            <Route path="itemMovementScanPersonnel-list" element={<ItemMovementScanPersonnelsList />} />
            <Route path="itemMovementScanPersonnel-add" element={<ItemMovementScanPersonnelsAdd />} />
            <Route path="itemMovementScanPersonnel-edit/:id" element={<ItemMovementScanPersonnelsEdit />} />
          </Route>

          <Route path="basketItems">
            <Route path="list" element={<BasketItemsList />} />
            <Route path="basketItems-add" element={<BasketItemsAdd />} />
            <Route path="basketItems-edit/:id" element={<BasketItemsEdit />} />
          </Route>
       
          <Route path="gameTables">
            <Route path="gameTable-list" element={<GameTablesList />} />
            <Route path="gameTable-add" element={<GameTablesAdd />} />
            <Route path="gameTable-edit/:id" element={<GameTablesEdit />} />
          </Route>
          <Route path="vendor">
            <Route path="list" element={<VendorsList />} />
            <Route path="add" element={<VendorsAdd />} />
            <Route path="edit/:id" element={<VendorsEdit />} />
          </Route>
          <Route path="importFieldMappings">
            <Route path="list" element={<ImportFieldMappingsList />} />
            <Route path="add" element={<ImportFieldMappingsAdd />} />
            <Route path="edit/:id" element={<ImportFieldMappingsEdit />} />
          </Route>
          <Route path="whacknowledgeStocks"  element={<WhAcknowledgeStocksList />}>
          </Route>
          <Route path="testPage">
            <Route path="list" element={<TestPageList />} />
          </Route>

          <Route path="importHistories">
            <Route path="list" element={<ImportHistoriesList />} />
          </Route>

          <Route path="requestCarriersOrBaskets">
            <Route path="list" element={<RequestCarriersOrBasketsList />} />
          </Route>

          <Route path="notificationPitToProcess">
            <Route path="list" element={<NotificationPitToProcessList />} />
            <Route path="edit/:id" element={<NotificationPitToProcessEdit />} />
          </Route>

          <Route path="whStockDetails">
            <Route path="list" element={<WhStockDetailsList />} />
          </Route>

          <Route path="pcStockDetails">
            <Route path="list" element={<PcStockDetailsList />} />
          </Route>

          <Route path="pcPitDetails">
            <Route path="list" element={<PcPitDetailsList />} />
          </Route>

          <Route path="pcAcknowledgeStocks">
            <Route path="list" element={<PcAcknowledgeStocksList />} />
          </Route>

          <Route path="pcReuseCarriers">
            <Route path="list" element={<PcReuseCarriersList />} />
          </Route>

          <Route path="pcSecurityCheckPointByCarriers">
            <Route path="list" element={<PcSecurityCheckPointByCarriersList />} />
          </Route>

          <Route path="whAcknowledgementHistories">
            <Route path="list" element={<WhAcknowledgementHistoriesList />} />
          </Route>

          <Route path="whTransferStocks">
            <Route path="main" element={<WhTransferStocksMain />} />
          </Route>

          <Route path="whReturnStocks">
            <Route path="list" element={<WhReturnStocksList />} />
          </Route>

          <Route path="whInvestigationRequests">
            <Route path="list" element={<WhInvestigationRequestsList />} />
          </Route>

          <Route path="prefixSuffixSettings">
            <Route path="list" element={<PrefixSuffixSettingsList />} />
            <Route path="add" element={<PrefixSuffixSettingsAdd />} />
            <Route path="edit/:id" element={<PrefixSuffixSettingsEdit />} />
          </Route>

          <Route path="importFiles">
            <Route path="list" element={<ImportFilesList />} />
          </Route>
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>vos
          <Route path="data-tables" element={<DataTables />} />
          <Route path="charts" element={<Charts />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
