import React from 'react';
import {
  CompassOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  BlockOutlined,
} from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.dashboard',
    key: 'dashboard',
    // TODO use path variable
    url: '/',

  },
  {
    title: 'common.user-management',
    key: 'usermain',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.userRoles',
        key: 'userRoles',
        url: '/user/userroleslist',
      },
      {
        title: 'common.user',
        key: 'user',
        url: '/user/userList',
      },
      {
        title: 'common.vendors',
        key: 'vendors',
        // TODO use path variable
        url: '/vendor/list',
        icon: <UserOutlined />,
      },
    ],
  },
  // {
  //   title: 'common.warehouse-management',
  //   key: 'warehousemain',
  //   icon: <UserOutlined />,
  //   children: [
  //     {
  //       title: 'common.warehouses',
  //       key: 'warehouses',
  //       url: '/warehouses/list',
  //     },
  //     {
  //       title: 'common.warehouselocations',
  //       key: 'warehouseLocationlist',
  //       url: '/warehouses/warehouseLocationlist',
  //     },
   
  //   ],
  // },
  {
    title: 'common.location-management',
    key: 'locationmain',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.locations',
        key: 'locations',
        url: '/localities/list',
      },
      {
        title: 'common.pitHubMapping',
        key: 'pitHubMapping',
        url: '/localities/pitHubMapping',
      }
   
    ],
  },
  {
    title: 'common.card-management',
    key: 'cardManagement',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.cardColors',
        key: 'cardColors',
        // TODO use path variable
        url: '/cardColors/list',
        icon: <UserOutlined />,
      },
      {
        title: 'common.cardGames',
        key: 'cardGames',
        // TODO use path variable
        url: '/cardGames/cardGame-list',
        icon: <UserOutlined />,
      },
      {
        title: 'common.cardTypes',
        key: 'cardTypes',
        // TODO use path variable
        url: '/cardTypes/list',
        icon: <UserOutlined />,
      }
    ],
  },
  // {
  //   title: 'common.processing-management',
  //   key: 'processingmain',
  //   icon: <UserOutlined />,
  //   children: [
  //     {
  //       title: 'common.processings',
  //       key: 'processings',
  //       url: '/processings/list',
  //     },
  //     {
  //       title: 'common.processinglocations',
  //       key: 'processingLocationlist',
  //       url: '/processings/processingLocationlist',
  //     },
   
  //   ],
  // },
  // {
  //   title: 'common.gamePits-management',
  //   key: 'gamePitsmain',
  //   icon: <UserOutlined />,
  //   children: [
  //     {
  //       title: 'common.gamePits',
  //       key: 'gamePits',
  //       // TODO use path variable
  //       url: '/gamepit/list',
  //     },
  //     {
  //       title: 'common.gamePitLocations',
  //       key: 'gamePitLocations',
  //       // TODO use path variable
  //       url: '/gamepit/gamePitLocationlist/',
   
  //     },
   
  //   ],
  // },
  {
    title: 'common.import-operation',
    key: 'importOperation',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.importData',
        key: 'importFiles',
        // TODO use path variable
        url: '/importFiles/list',
        icon: <UserOutlined />,
      },
      {
        title: 'common.importHistories',
        key: 'importHistories',
        // TODO use path variable
        url: '/importHistories/list',
        icon: <UserOutlined />,
      },
      {
        title: 'common.importFieldMappings',
        key: 'importFieldMappings',
        // TODO use path variable
        url: '/importFieldMappings/list',
        icon: <UserOutlined />,
      },
      {
        title: 'common.prefixSuffixSettings',
        key: 'prefixSuffixSettings',
        // TODO use path variable
        url: '/prefixSuffixSettings/list',
        icon: <UserOutlined />,
      },
    ],
  },
  {
    title: 'common.warehouse-operation',
    key: 'warehouseOperation',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.whacknowledgeStocks',
        key: 'whacknowledgeStocks',
        // TODO use path variable
        url: '/whacknowledgeStocks/',
        icon: <UserOutlined />,
      },
      {
        title: 'common.whStockDetails',
        key: 'whStockDetails',
        // TODO use path variable
        url: '/whStockDetails/list',
        icon: <UserOutlined />
      },
      {
        title: 'common.whAcknowledgementHistories',
        key: 'whAcknowledgementHistories',
        // TODO use path variable
        url: '/whAcknowledgementHistories/list',
        icon: <UserOutlined />
      },
      {
        title: 'common.whTransferStocks',
        key: 'whTransferStocks',
        // TODO use path variable
        url: '/whTransferStocks/main',
        icon: <UserOutlined />
      },
      {
        title: 'common.whReturnStocks',
        key: 'whReturnStocks',
        // TODO use path variable
        url: '/whReturnStocks/list',
        icon: <UserOutlined />
      },
   
    ],
  },
  {
    title: 'common.cardRoom-operation',
    key: 'cardRoomOperation',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.pcStockDetails',
        key: 'pcStockDetails',
        // TODO use path variable
        url: '/pcStockDetails/list',
        icon: <UserOutlined />
      },
      {
        title: 'common.pcPitDetails',
        key: 'pcPitDetails',
        // TODO use path variable
        url: '/pcPitDetails/list',
        icon: <UserOutlined />
      },
      {
        title: 'common.pcAcknowledgeStocks',
        key: 'pcAcknowledgeStocks',
        // TODO use path variable
        url: '/pcAcknowledgeStocks/list',
        icon: <UserOutlined />
      },
      {
        title: 'common.pcReuseCarriers',
        key: 'pcReuseCarriers',
        // TODO use path variable
        url: '/pcReuseCarriers/list',
        icon: <UserOutlined />
      },
      {
        title: 'common.pcSecurityCheckPointByCarriers',
        key: 'pcSecurityCheckPointByCarriers',
        // TODO use path variable
        url: '/pcSecurityCheckPointByCarriers/list',
        icon: <UserOutlined />
      },
    ],
  },
  {
    title: 'common.pitRoom-operation',
    key: 'pitRoomOperation',
    icon: <UserOutlined />,
    children: [
      {
        title: 'common.notificationPitToProcess',
        key: 'notificationPitToProcess',
        // TODO use path variable
        url: '/notificationPitToProcess/list',
        icon: <UserOutlined />,
      },
    ],
  },
  
  {
    title: 'common.sealColors',
    key: 'sealColors',
    // TODO use path variable
    url: '/sealColors/list',
    icon: <UserOutlined />,
  },
  {
    title: 'common.itemTypes',
    key: 'itemTypes',
    // TODO use path variable
    url: '/itemTypes/list',
    icon: <UserOutlined />,
  },
  {
    title: 'common.itemMovementScanPersonnels',
    key: 'itemMovementScanPersonnels',
    // TODO use path variable
    url: '/itemMovementScanPersonnels/itemMovementScanPersonnel-list',
    icon: <UserOutlined />,
  },
  {
    title: 'common.basketItems',
    key: 'basketItems',
    // TODO use path variable
    url: '/basketItems/list',
    icon: <UserOutlined />,
  },

  // {
  //   title: 'common.gameTables',
  //   key: 'gameTables',
  //   // TODO use path variable
  //   url: '/gameTables/gameTable-list',
  //   icon: <UserOutlined />,
  // },
  {
    title: 'common.TestPage',
    key: 'testpage',
    // TODO use path variable
    url: '/testPage/list',
    icon: <UserOutlined />,
  },
  
  {
    title: 'common.requestCarriersOrBaskets',
    key: 'requestCarriersOrBaskets',
    // TODO use path variable
    url: '/requestCarriersOrBaskets/list',
    icon: <UserOutlined />,
  },
  
  {
    title: 'common.whInvestigationRequests',
    key: 'whInvestigationRequests',
    // TODO use path variable
    url: '/whInvestigationRequests/list',
    icon: <UserOutlined />
  },
  // {
  //   title: 'common.nft-dashboard',
  //   key: 'nft-dashboard',
  //   // TODO use path variable
  //   url: '/',
  //   icon: <NftIcon />,
  // },
  // {
  //   title: 'common.medical-dashboard',
  //   key: 'medical-dashboard',
  //   url: '/medical-dashboard',
  //   icon: <DashboardOutlined />,
  // },
  // {
  //   title: 'common.ItemType',
  //   key: 'itemtype-list',
  //   url: '/itemtype-list',
  //   icon: <DashboardOutlined />,
  // },
  // {
  //   title: 'common.apps',
  //   key: 'apps',
  //   icon: <HomeOutlined />,
  //   children: [
  //     {
  //       title: 'common.feed',
  //       key: 'feed',
  //       url: '/apps/feed',
  //     },
  //   ],
  // },
  // {
  //   title: 'common.authPages',
  //   key: 'auth',
  //   icon: <UserOutlined />,
  //   children: [
  //     {
  //       title: 'common.login',
  //       key: 'login',
  //       url: '/auth/login',
  //     },
  //     {
  //       title: 'common.signUp',
  //       key: 'singUp',
  //       url: '/auth/sign-up',
  //     },
  //     {
  //       title: 'common.lock',
  //       key: 'lock',
  //       url: '/auth/lock',
  //     },
  //     {
  //       title: 'common.forgotPass',
  //       key: 'forgotPass',
  //       url: '/auth/forgot-password',
  //     },
  //     {
  //       title: 'common.securityCode',
  //       key: 'securityCode',
  //       url: '/auth/security-code',
  //     },
  //     {
  //       title: 'common.newPassword',
  //       key: 'newPass',
  //       url: '/auth/new-password',
  //     },
  //   ],
  // },
  // {
  //   title: 'common.forms',
  //   key: 'forms',
  //   icon: <FormOutlined />,
  //   children: [
  //     {
  //       title: 'common.advancedForms',
  //       key: 'advanced-forms',
  //       url: '/forms/advanced-forms',
  //     },
  //   ],
  // },
  // {
  //   title: 'common.dataTables',
  //   key: 'dataTables',
  //   url: '/data-tables',
  //   icon: <TableOutlined />,
  // },
  // {
  //   title: 'common.charts',
  //   key: 'charts',
  //   url: '/charts',
  //   icon: <LineChartOutlined />,
  // },
  // {
  //   title: 'common.maps',
  //   key: 'maps',
  //   icon: <CompassOutlined />,
  //   children: [
  //     {
  //       title: 'common.googleMap',
  //       key: 'google-maps',
  //       url: '/maps/google-maps',
  //     },
  //     {
  //       title: 'common.leafletMap',
  //       key: 'leaflet-maps',
  //       url: '/maps/leaflet-maps',
  //     },
  //     {
  //       title: 'common.reactSimpleMaps',
  //       key: 'react-simple-maps',
  //       url: '/maps/react-simple-maps',
  //     },
  //     {
  //       title: 'common.pigeonMaps',
  //       key: 'pigeon-maps',
  //       url: '/maps/pigeon-maps',
  //     },
  //   ],
  // },
  // {
  //   title: 'common.pages',
  //   key: 'pages',
  //   icon: <LayoutOutlined />,
  //   children: [
  //     {
  //       title: 'common.profilePage',
  //       key: 'profile',
  //       url: '/profile',
  //     },
  //     {
  //       title: 'common.serverError',
  //       key: 'serverError',
  //       url: '/server-error',
  //     },
  //     {
  //       title: 'common.clientError',
  //       key: '404Error',
  //       url: '/404',
  //     },
  //   ],
  // },
  // {
  //   title: 'common.ui',
  //   key: 'ui',
  //   icon: <BlockOutlined />,
  //   children: [
  //     {
  //       title: 'common.alert',
  //       key: 'alert',
  //       url: '/ui-components/alert',
  //     },
  //     {
  //       title: 'common.avatar',
  //       key: 'avatar',
  //       url: '/ui-components/avatar',
  //     },
  //     {
  //       title: 'common.autocomplete',
  //       key: 'auto-complete',
  //       url: '/ui-components/auto-complete',
  //     },
  //     {
  //       title: 'common.badge',
  //       key: 'badge',
  //       url: '/ui-components/badge',
  //     },
  //     {
  //       title: 'common.breadcrumbs',
  //       key: 'breadcrumbs',
  //       url: '/ui-components/breadcrumbs',
  //     },
  //     {
  //       title: 'common.button',
  //       key: 'button',
  //       url: '/ui-components/button',
  //     },
  //     {
  //       title: 'common.checkbox',
  //       key: 'checkbox',
  //       url: '/ui-components/checkbox',
  //     },
  //     {
  //       title: 'common.collapse',
  //       key: 'collapse',
  //       url: '/ui-components/collapse',
  //     },
  //     {
  //       title: 'common.dateTimePicker',
  //       key: 'dateTimePicker',
  //       url: '/ui-components/date-time-picker',
  //     },
  //     {
  //       title: 'common.dropdown',
  //       key: 'dropdown',
  //       url: '/ui-components/dropdown',
  //     },
  //     {
  //       title: 'common.input',
  //       key: 'input',
  //       url: '/ui-components/input',
  //     },
  //     {
  //       title: 'common.modal',
  //       key: 'modal',
  //       url: '/ui-components/modal',
  //     },
  //     {
  //       title: 'common.notification',
  //       key: 'notification',
  //       url: '/ui-components/notification',
  //     },
  //     {
  //       title: 'common.pagination',
  //       key: 'pagination',
  //       url: '/ui-components/pagination',
  //     },
  //     {
  //       title: 'common.popconfirm',
  //       key: 'popconfirm',
  //       url: '/ui-components/popconfirm',
  //     },
  //     {
  //       title: 'common.popover',
  //       key: 'popover',
  //       url: '/ui-components/popover',
  //     },
  //     {
  //       title: 'common.progress',
  //       key: 'progress',
  //       url: '/ui-components/progress',
  //     },
  //     {
  //       title: 'common.radio',
  //       key: 'radio',
  //       url: '/ui-components/radio',
  //     },
  //     {
  //       title: 'common.rate',
  //       key: 'rate',
  //       url: '/ui-components/rate',
  //     },
  //     {
  //       title: 'common.result',
  //       key: 'result',
  //       url: '/ui-components/result',
  //     },
  //     {
  //       title: 'common.select',
  //       key: 'select',
  //       url: '/ui-components/select',
  //     },
  //     {
  //       title: 'common.skeleton',
  //       key: 'skeleton',
  //       url: '/ui-components/skeleton',
  //     },
  //     {
  //       title: 'common.spinner',
  //       key: 'spinner',
  //       url: '/ui-components/spinner',
  //     },
  //     {
  //       title: 'common.steps',
  //       key: 'steps',
  //       url: '/ui-components/steps',
  //     },
  //     {
  //       title: 'common.switch',
  //       key: 'switch',
  //       url: '/ui-components/switch',
  //     },
  //     {
  //       title: 'common.tabs',
  //       key: 'tabs',
  //       url: '/ui-components/tabs',
  //     },
  //     {
  //       title: 'common.upload',
  //       key: 'upload',
  //       url: '/ui-components/upload',
  //     },
  //   ],
  // },
];
