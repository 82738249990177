

export const getIPV4 = async (): Promise<string> => {
  try {

    const ipCheck = await fetch('https://geolocation-db.com/json/');
    const data = await ipCheck.json();
    const response =data.IPv4;

    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};
