import { BaseResponse } from '@app/entities/BaseResponse';
import { commonHttpApi } from './commonHttp.api';
import { ApiError } from './ApiError';
import i18n from '@app/i18n';
import { StringLiteral } from 'typescript';
import { IList } from '@app/entities/IListResponse';

export interface ProcessingLocationsRequest {
    name: string;
    publish:boolean;
    processingId:string;
    createdByIpAddress:string;
  }
  
  export interface UpdateProcessingLocations {
    id: string;
    name: string;
    publish:boolean;
    processingId:string;
    updatedByUserId:string;
    updatedByIpAddress:string;
  }

  export interface DeleteProcessingLocations {
    deletedByIpAddress:string;
    
    deletedByUserId:string;
  }

  export interface ProcessingLocationsResponse {
    id: string;
    name: string;
    processingName:string;
    publish:boolean;
    createdByIpAddress:string;
    createdByProcessingLocations:string;
    updatedByProcessingLocations:string;
    updatedipAddress:string;
  }

  export interface ProcessingLocationsByIdResponse {
    id: string;
    name: string;
    publish:boolean;
    processingId:string;
  }

  export const getProcessingLocationsByPaging = async (pageSize:number,pageNumber:number,name:string,processingId:string): Promise<BaseResponse<IList<ProcessingLocationsResponse>> | undefined> => {
    let result: BaseResponse<IList<ProcessingLocationsResponse>> | undefined = undefined;
    
    await commonHttpApi
      .get<BaseResponse<IList<ProcessingLocationsResponse>>>('ProcessingLocations/ByPaging',{ params: { pageSize: pageSize,pageNumber:pageNumber,name:name,processingId:processingId } })
      .then(({ data }) => {
  
        result = data;
        
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const getProcessingLocations = async (): Promise<BaseResponse<ProcessingLocationsResponse[]> | undefined> => {
    let result: BaseResponse<ProcessingLocationsResponse[]> | undefined = undefined;

    await commonHttpApi
      .get<BaseResponse<ProcessingLocationsResponse[]>>('ProcessingLocations')
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const getProcessingLocationsById = async (ProcessingLocationPayload: string): Promise<BaseResponse<ProcessingLocationsByIdResponse> | undefined> => {
    let result: BaseResponse<ProcessingLocationsByIdResponse> | undefined = undefined;
    await commonHttpApi
      .get<BaseResponse<ProcessingLocationsByIdResponse>>('ProcessingLocations/' + ProcessingLocationPayload)
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status == 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

export const doAddProcessingLocations = async (
    ProcessingLocationPayload: ProcessingLocationsRequest,
  ): Promise<BaseResponse<ProcessingLocationsResponse> | undefined> => {
    let result: BaseResponse<ProcessingLocationsResponse> | undefined = undefined;
  
    await commonHttpApi
      .post<BaseResponse<ProcessingLocationsResponse>>('ProcessingLocations', { ...ProcessingLocationPayload })
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status === 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const doUpdateProcessingLocations = async (
    ProcessingLocationId: string,
    ProcessingLocationPayload: UpdateProcessingLocations,
  ): Promise<BaseResponse<ProcessingLocationsResponse> | undefined> => {
    let result: BaseResponse<ProcessingLocationsResponse> | undefined = undefined;

    await commonHttpApi
      .put<BaseResponse<ProcessingLocationsResponse>>('ProcessingLocations/UpdateProcessingLocations/' + ProcessingLocationId, {
        ...ProcessingLocationPayload,
      })
      .then(({ data }) => {
        result = data;
      })
      .catch((err) => {
        if (err.options) {
          if (err.options.status == 401) {
            switch (err.options.code) {
              case '1000002':
              case '1000013':
                throw new ApiError(i18n.t('login.loginError'));
            }
          }
        }
  
        throw new ApiError(i18n.t('serverError.main'));
      });
  
    return result;
  };

  export const doDeleteProcessingLocation = async (
    ProcessingLocationId: string,
    ProcessingLocationPayload: DeleteProcessingLocations,
  ): Promise<BaseResponse<ProcessingLocationsResponse> | undefined> => {
    let result: BaseResponse<ProcessingLocationsResponse> | undefined = undefined;
    await commonHttpApi
    .put<BaseResponse<ProcessingLocationsResponse>>('ProcessingLocations/DeleteProcessingLocations/' + ProcessingLocationId, {
      ...ProcessingLocationPayload,
    })
    .then(({ data }) => {
      result = data;
    })
    .catch((err) => {
      if (err.options) {
        if (err.options.status == 401) {
          switch (err.options.code) {
            case '1000002':
            case '1000013':
              throw new ApiError(i18n.t('login.loginError'));
          }
        }
      }

      throw new ApiError(i18n.t('serverError.main'));
    });

  return result;
  };
  