import React, { useState, useEffect, ReactNode } from 'react';
import { BasicTableRow, Pagination } from 'api/table.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import * as S from './Processings.styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseAvatar } from '../../common/BaseAvatar/BaseAvatar';
import { BaseUpload } from '../../common/BaseUpload/BaseUpload';
import { UploadOutlined, InboxOutlined, AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { BaseSpace } from '../../common/BaseSpace/BaseSpace';
import { BaseArticle } from '../../common/BaseArticle/BaseArticle';
import { getNews, Post } from '@app/api/news.api';
import { BaseSelect } from '../../common/selects/BaseSelect/BaseSelect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProcessingsByIdResponse, doUpdateProcessings, getProcessingsById } from '@app/api/processings.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseCheckbox } from '../../common/BaseCheckbox/BaseCheckbox';
import { getIPV4 } from '@app/api/getIPAddress.api';
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

const initialProcessings = {
  name: '',
  updatedByUserId: "1",
  updatedByIpAddress: '127.0.0',
  publish: true
};

export const ProcessingsEdit: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: ProcessingsByIdResponse; loading: boolean }>({
    data:{} as ProcessingsByIdResponse,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [isPreview, setPreview] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(initialProcessings);

  const { id } = useParams();

  const navigate = useNavigate();

  const handleOnchange = (text: any, input: string) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  useEffect(() => {
    console.log(id);
    setLoading(true);
    getProcessingsData();
  }, [isMounted]);

  const getProcessingsData = async () => {
    try {
      // Make a GET request using axios
      const response = await getProcessingsById(id!);
  
      // if (isMounted.current) {
      const responseData = response!.data;

      // Destructure values from the response data
      const { name, publish } = responseData;

      // Check if title and remark have valid values

      setInputs({
        name: name,
        publish: publish,
        updatedByIpAddress:'',
        updatedByUserId:"1"

      });

      form.setFieldsValue({
        name: name,
        publish: publish
      });
   
      setTableData({ data: responseData, loading: false });
      // }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const checkValidator = async () => {
    if (inputs.name !== '') {
    
      updateProcessings();
    }
  };

  const updateProcessings = async () => {
    try {
        inputs.updatedByIpAddress = await getIPV4();
        inputs.updatedByUserId ="1";

      const response = await doUpdateProcessings(id!,{name:inputs.name,publish:inputs.publish, updatedByUserId: inputs.updatedByUserId ,updatedByIpAddress:inputs.updatedByIpAddress,id:id!});

      if (response!.result.success) {
        notificationController.success({ message: 'Update processings success' });
        navigate('/processings/list');
      }
    } catch (error) {
      console.log('Error add data: ', error);
    }
  };

  const ProcessingsInfo = (
    <S.Card id="basic-table" padding="1.25rem 1.25rem 0">
    <BaseButtonsForm 
    form={form} 
    isFieldsChanged={isFieldsChanged}
    name="userForm"
    loading={isLoading}
    layout="vertical">
      <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
      
        <BaseCol xs={24} md={24}>
          <BaseButtonsForm.Item
          name="name"
          label={t('processings.name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <BaseInput onChange={(e) => handleOnchange(e.target.value, 'name')} />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={24} md={24}>
          <BaseButtonsForm.Item name="publish" label={t('processings.publish')}>
            <BaseCheckbox
              checked={inputs.publish}
              
              onChange={(e) => handleOnchange(e.target.checked, 'publish')}
            >
              {t('processings.publish')}
            </BaseCheckbox>
          </BaseButtonsForm.Item>
        </BaseCol>
    
      </BaseRow>
      <BaseButtonsForm.Item>
        <BaseSpace style={{ float: 'right' }}>
          <BaseButton type="primary" onClick={() => checkValidator()}>
          {t('common.save')}
          </BaseButton>
        </BaseSpace>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  </S.Card>
  )
  const options = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  return (
    <>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} md={24} xl={24}>

        <S.Card title={t('processings.editProcessings')}>
          <BaseTabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: (
                  <span>
                    <AppleOutlined />
                    {t('processings.info')} 
                  </span>
                ),
                children: ProcessingsInfo,
              }
            ]}
          />
        </S.Card>

        </BaseCol>
      </BaseRow>
    </>
  );
};
